<template>
	<v-container fluid style="padding: 16px 32px">
		<v-card
			class="mx-auto"
			outlined
		>
			<v-card-title class="headline">O nas:</v-card-title>

			<v-card-subtitle style="color: #FFFFFF;padding-left: 22px">
				<v-container fluid>
					<v-row>
						<v-col cols="12" lg="3">
							<v-img class="defaultImageStyle" src="../assets/about/Pyrolysis_About.jpg" alt="Działalność spółki Elibor" width="auto" contain></v-img>
						</v-col>
						<v-col cols="12" lg="8">
							<p class="defaultTextStyle">
								Działalność spółki Elibor skupia się na znajdowaniu najnowocześniejszych technologii oraz rozwiązań gotowych do zastosowania głównie w energetyce i medycynie.
								Jednym z najdłużej realizowanych i opracowywanych przez Spółkę projektów jest instalacja do pirolizy opon samochodowych.
							</p>
							<br>
							<p class="defaultTextStyle">
								W 2014 r. otrzymaliśmy pierwszy prospekt z tego typu instalacją. Choć dysponowaliśmy wówczas zaawansowaną technologią spalania plastiku to zdecydowaliśmy się
								przesunąć nasze siły i działania na korzyść pirolizy opon samochodowych. Po kilku latach różnego rodzaju badań, opracowaliśmy linię technologiczną,
								która pozwala wykorzystać potencjał drzemiący w tym surowcu w jak najwyższym stopniu.
							</p>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" lg="9" md="12">
							<p class="defaultTextStyle">
								Za jeden z najważniejszych sukcesów Spółki uważamy nawiązanie wielu kontaktów z zagranicznymi producentami aparatury,
								ciężkich maszyn oraz oprogramowania, co pozwala nam na swobodny proces tworzenia i udoskonalania produktu oraz poszukiwanie
								nowych pomysłów i śledzenie trendów w tej gałęzi energetyki.
							</p>

						</v-col>
					</v-row>
				</v-container>
			</v-card-subtitle>
		</v-card>
	</v-container>
</template>
<script>
export default {
  inheritAttrs: false,
  name: 'about',
  props: [],
  data: function () {
    return {}
  },
  mounted: function () {
  },
  computed: {},
  methods: {}
}
</script>
<style scoped>
</style>
